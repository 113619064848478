var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"cols":"12","md":_vm.currentBreakPount == 'xxl' ? 6 : 7}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Users"}},[_c('v-select',{attrs:{"id":"users","placeholder":"Select user","label":"name","reduce":function (value) { return value.id; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.users},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"From"}},[_c('b-form-datepicker',{attrs:{"id":"from","date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }},model:{value:(_vm.filter.from),callback:function ($$v) {_vm.$set(_vm.filter, "from", $$v)},expression:"filter.from"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"To"}},[_c('b-form-datepicker',{attrs:{"id":"to","date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }},model:{value:(_vm.filter.to),callback:function ($$v) {_vm.$set(_vm.filter, "to", $$v)},expression:"filter.to"}})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":_vm.currentBreakPount == 'xxl' ? 6 : 5}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":_vm.currentBreakPount == 'xxl' ? 3 : 4}},[_c('b-button',{staticClass:"mr-1 mb-lg-0 mb-md-0 mb-sm-1 mb-1 w-100",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.getData()}}},[_c('feather-icon',{staticClass:"mr-50 text-center cursor-pointer",attrs:{"icon":"SearchIcon"}}),_vm._v("SEARCH ")],1)],1),_c('b-col',{attrs:{"cols":"12","md":_vm.currentBreakPount == 'xxl' ? 3 : 4}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-backdrop",modifiers:{"sidebar-backdrop":true}}],staticClass:"mr-1 mb-lg-0 mb-md-0 mb-sm-1 mb-1 w-100",attrs:{"variant":"outline-info"},on:{"click":function($event){return _vm.getMoreInfo()}}},[_c('feather-icon',{staticClass:"mr-50 text-center cursor-pointer",attrs:{"icon":"InfoIcon"}}),_vm._v("MORE INFO ")],1)],1)],1)],1)],1),(_vm.filterUser.length > 0)?_c('b-table-simple',{ref:"table_report_modul",staticClass:"custom-table mt-1",attrs:{"bordered":"","small":"","caption-top":"","responsive":"","cellspacing":"0","cellpadding":"0"}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Module")]),_vm._l((_vm.fields),function(item,index){return [(index != _vm.fields.length - 1)?_c('b-th',{key:index,class:[
              'text-center item-header',
              item.date == _vm.today ? 'bg-th-today' : '' ]},[_vm._v(" "+_vm._s(item.format_day)+" ")]):_vm._e()]})],2)],1),_c('b-tbody',_vm._l((_vm.filterUser),function(item,index){return _c('b-tr',{key:index},[_c('b-td',{staticClass:"text-center item-user"},[_c('div',{staticClass:"container"},[_c('p',[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('span')]),_vm._l((item.json_data),function(schedule,i){return [(i != item.json_data.length - 1)?_c('b-td',{key:i},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[
                _vm.bgSchedule(schedule),
                'item-hour',
                _vm.isDarkTheme ? 'item-hour-dark' : 'item-hour-light',
                schedule.date == _vm.today ? 'bg-hour-today' : '' ],attrs:{"title":_vm.titleSchedule(schedule, 1)},on:{"click":function($event){return _vm.openDetails(item, schedule, schedule.checking_m, 1)}}},[(schedule.status == 1 || schedule.checking_m)?_c('p',[_vm._v(" "+_vm._s(schedule.checking_m)+" ")]):_c('p',[_vm._v("00:00:00")])]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[
                'bg-default item-hour',
                _vm.isDarkTheme ? 'item-hour-dark' : 'item-hour-light',
                schedule.date == _vm.today ? 'bg-hour-today' : '' ],attrs:{"title":_vm.titleSchedule(schedule, 2)},on:{"click":function($event){return _vm.openDetails(item, schedule, schedule.m_begining_break, 2)}}},[_vm._v(" "+_vm._s(schedule.m_begining_break ? schedule.m_begining_break : "00:00:00")+" ")]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[
                'bg-default item-hour',
                _vm.isDarkTheme ? 'item-hour-dark' : 'item-hour-light',
                schedule.date == _vm.today ? 'bg-hour-today' : '' ],attrs:{"title":_vm.titleSchedule(schedule, 3)},on:{"click":function($event){return _vm.openDetails(item, schedule, schedule.m_finish_break, 3)}}},[_vm._v(" "+_vm._s(schedule.m_finish_break ? schedule.m_finish_break : "00:00:00")+" ")]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[
                'bg-default item-hour',
                _vm.isDarkTheme ? 'item-hour-dark' : 'item-hour-light',
                schedule.date == _vm.today ? 'bg-hour-today ' : '' ],attrs:{"title":_vm.titleSchedule(schedule, 4)},on:{"click":function($event){return _vm.openDetails(item, schedule, schedule.m_checkout, 4)}}},[_vm._v(" "+_vm._s(schedule.m_checkout ? schedule.m_checkout : "00:00:00")+" ")]),_c('div',{class:[
                'item-count-hours',
                _vm.isDarkTheme ? 'text-light' : '',
                schedule.date == _vm.today ? 'bg-count-hours-today' : 'bg-hours' ]},[_vm._v(" HOURS: "+_vm._s(schedule.horavalidada ? schedule.horavalidada : 0)+" ")])]):_vm._e()]})],2)}),1)],1):_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center"},[_vm._v("There are no records to show")])])],1),_c('b-modal',{attrs:{"title":_vm.titleModal,"size":"lg","body-class":"p-0","title-class":"title-assitence-schedule","hide-footer":""},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('b-container',{staticClass:"p-4",attrs:{"fluid":""}},[_c('div',{staticClass:"text-center"},[_c('b-img',{staticClass:"image-captured",attrs:{"src":_vm.detailsSchedule.image,"fluid":"","alt":"Responsive image"}})],1),_c('b-row',{staticClass:"py-2"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('feather-icon',{staticClass:"mr-50 text-center",attrs:{"icon":"CheckCircleIcon"}}),_vm._v(" ASSIGNED: "+_vm._s(_vm.detailsSchedule.assigned)+" ")],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('feather-icon',{staticClass:"mr-50 text-center",attrs:{"icon":"CheckCircleIcon"}}),_vm._v(" ASSISTANCE: "+_vm._s(_vm.detailsSchedule.assistance)+" ")],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('feather-icon',{staticClass:"mr-50 text-center",attrs:{"icon":"CheckCircleIcon"}}),_vm._v(" IP ADDRESS: "+_vm._s(_vm.detailsSchedule.ip)+" ")],1)],1)],1)],1),_c('b-sidebar',{staticStyle:{"width":"700px !important"},attrs:{"id":"sidebar-backdrop","title":"More info","bg-variant":"white","sidebar-class":"sidebar-lg-schedule sidebar-schedules","header-class":"pt-1 d-flex justify-content-between align-items-center","backdrop":"","shadow":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
              var hide = ref.hide;
return [_c('span',[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"30"},on:{"click":function($event){return hide()}}})],1),_c('span',[_c('h1',[_vm._v("More Info")])])]}}])},[_c('div',{staticClass:"px-3 py-2"},[_c('content-more-info-report',{key:_vm.keyMoreInfo,attrs:{"data":_vm.dataSchedule,"by-user":true}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }