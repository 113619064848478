<template>
  <div>
    <b-row class="d-flex align-items-center">
      <b-col cols="12" :md="currentBreakPount == 'xxl' ? 6 : 7">
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="Users">
              <v-select
                id="users"
                v-model="selectedUser"
                placeholder="Select user"
                label="name"
                :reduce="(value) => value.id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="users"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="From">
              <b-form-datepicker
                id="from"
                v-model="filter.from"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="To">
              <b-form-datepicker
                id="to"
                v-model="filter.to"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" :md="currentBreakPount == 'xxl' ? 6 : 5">
        <b-row>
          <b-col cols="12" :md="currentBreakPount == 'xxl' ? 3 : 4">
            <b-button
              variant="primary"
              class="mr-1 mb-lg-0 mb-md-0 mb-sm-1 mb-1 w-100"
              @click="getData()"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50 text-center cursor-pointer"
              />SEARCH
            </b-button>
          </b-col>
          <b-col cols="12" :md="currentBreakPount == 'xxl' ? 3 : 4">
            <b-button
              variant="outline-info"
              class="mr-1 mb-lg-0 mb-md-0 mb-sm-1 mb-1 w-100"
              v-b-toggle.sidebar-backdrop
              @click="getMoreInfo()"
            >
              <feather-icon
                icon="InfoIcon"
                class="mr-50 text-center cursor-pointer"
              />MORE INFO
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-table-simple
      bordered
      small
      caption-top
      responsive
      ref="table_report_modul"
      class="custom-table mt-1"
      cellspacing="0"
      cellpadding="0"
      v-if="filterUser.length > 0"
    >
      <b-thead>
        <b-tr>
          <b-th>Module</b-th>
          <template v-for="(item, index) in fields">
            <b-th
              :key="index"
              :class="[
                'text-center item-header',
                item.date == today ? 'bg-th-today' : '',
              ]"
              v-if="index != fields.length - 1"
            >
              {{ item.format_day }}
            </b-th>
          </template>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in filterUser" :key="index">
          <b-td class="text-center item-user">
            <div class="container">
              <p>
                {{ item.name }}
              </p>
            </div>
            <span></span>
          </b-td>
          <template v-for="(schedule, i) in item.json_data">
            <b-td :key="i" v-if="i != item.json_data.length - 1">
              <div
                :class="[
                  bgSchedule(schedule),
                  'item-hour',
                  isDarkTheme ? 'item-hour-dark' : 'item-hour-light',
                  schedule.date == today ? 'bg-hour-today' : '',
                ]"
                @click="openDetails(item, schedule, schedule.checking_m, 1)"
                v-b-tooltip.hover
                :title="titleSchedule(schedule, 1)"
              >
                <p v-if="schedule.status == 1 || schedule.checking_m">
                  {{ schedule.checking_m }}
                </p>
                <p v-else>00:00:00</p>
              </div>
              <div
                :class="[
                  'bg-default item-hour',
                  isDarkTheme ? 'item-hour-dark' : 'item-hour-light',
                  schedule.date == today ? 'bg-hour-today' : '',
                ]"
                @click="
                  openDetails(item, schedule, schedule.m_begining_break, 2)
                "
                v-b-tooltip.hover
                :title="titleSchedule(schedule, 2)"
              >
                {{
                  schedule.m_begining_break
                    ? schedule.m_begining_break
                    : "00:00:00"
                }}
              </div>
              <div
                :class="[
                  'bg-default item-hour',
                  isDarkTheme ? 'item-hour-dark' : 'item-hour-light',
                  schedule.date == today ? 'bg-hour-today' : '',
                ]"
                @click="openDetails(item, schedule, schedule.m_finish_break, 3)"
                v-b-tooltip.hover
                :title="titleSchedule(schedule, 3)"
              >
                {{
                  schedule.m_finish_break ? schedule.m_finish_break : "00:00:00"
                }}
              </div>
              <div
                :class="[
                  'bg-default item-hour',
                  isDarkTheme ? 'item-hour-dark' : 'item-hour-light',
                  schedule.date == today ? 'bg-hour-today ' : '',
                ]"
                @click="openDetails(item, schedule, schedule.m_checkout, 4)"
                v-b-tooltip.hover
                :title="titleSchedule(schedule, 4)"
              >
                {{ schedule.m_checkout ? schedule.m_checkout : "00:00:00" }}
              </div>
              <div
                :class="[
                  'item-count-hours',
                  isDarkTheme ? 'text-light' : '',
                  schedule.date == today ? 'bg-count-hours-today' : 'bg-hours',
                ]"
              >
                HOURS: {{ schedule.horavalidada ? schedule.horavalidada : 0 }}
              </div>
            </b-td>
          </template>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-row v-else class="mt-3">
      <b-col cols="12">
        <p class="text-center">There are no records to show</p>
      </b-col>
    </b-row>
    
    <!-- *************** MORE DETAILS SCHEDULE ******************* -->
    <b-modal
      v-model="modal"
      :title="titleModal"
      size="lg"
      body-class="p-0"
      title-class="title-assitence-schedule"
      hide-footer
    >
      <b-container fluid class="p-4">
        <div class="text-center">
          <b-img
            :src="detailsSchedule.image"
            fluid
            class="image-captured"
            alt="Responsive image"
          ></b-img>
        </div>
        <b-row class="py-2">
          <b-col cols="auto">
            <feather-icon icon="CheckCircleIcon" class="mr-50 text-center" />
            ASSIGNED: {{ detailsSchedule.assigned }}
          </b-col>
          <b-col cols="auto">
            <feather-icon icon="CheckCircleIcon" class="mr-50 text-center" />
            ASSISTANCE: {{ detailsSchedule.assistance }}
          </b-col>
          <b-col cols="auto">
            <feather-icon icon="CheckCircleIcon" class="mr-50 text-center" />
            IP ADDRESS: {{ detailsSchedule.ip }}
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- ***************** MORE INFO **************** -->
    <b-sidebar
      id="sidebar-backdrop"
      title="More info"
      bg-variant="white"
      sidebar-class="sidebar-lg-schedule sidebar-schedules"
      header-class="pt-1 d-flex justify-content-between align-items-center"
      backdrop
      shadow
      style="width: 700px !important"
    >
      <template #header="{ hide }">
        <span
          ><feather-icon
            icon="XIcon"
            size="30"
            class="cursor-pointer"
            @click="hide()"
          />
        </span>
        <span><h1>More Info</h1></span>
      </template>
      <div class="px-3 py-2">
        <content-more-info-report
          :data="dataSchedule"
          :key="keyMoreInfo"
          :by-user="true"
        />
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import SchedulesServices from "./services/schedules.service";
import contentMoreInfoReport from "./modals/contentInfoReportModul.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import vSelect from "vue-select";
export default {
  components: {
    contentMoreInfoReport,
    vSelect,
  },
  data() {
    return {
      fields: [],
      items: [],
      titleModal: null,
      modal: false,
      schedule: {},
      dataSchedule: [],
      filter: {
        from: moment().startOf("week").add(1, "days").format("YYYY-MM-DD"),
        to: moment().endOf("week").add(1, "days").format("YYYY-MM-DD"),
        searchUser: "",
      },
      keyMoreInfo: 0,
      bgBtnAdres: "background-color: rgba(51, 59, 81, 0.2) !important",
      detailsSchedule: {},
      selectedUser: null,
      today: moment().format("YYYY-MM-DD"),
      filterUser: [],
      users: [],
    };
  },
  mounted() {
    this.getUsersForScheduleFilter();
    this.getData();
  },
  methods: {
    async getReport() {
      try {
        this.addPreloader();
        this.items = [];
        const params = {
          modul_id: this.currentUser.modul_id,
          start_date: this.filter.from,
          end_date: this.filter.to,
          user_id: null,
        };
        const data = await SchedulesServices.getHourReport(params);
        let array = data.data;
        array.forEach((element) => {
          this.items.push({
            id: element.id,
            user_name: element.name_user,
            json_data: element.json_data,
          });
        });
        this.dataByDay(data);
        this.removePreloader();
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    dataByDay(data) {
      this.fields = [];
      let array = data.days;
      this.fields.push({
        key: "field",
        label: "USER",
        sortable: false,
      });
      array.forEach((element) => {
        let letter = element.dayheader.toUpperCase();

        this.fields.push({
          key: "field",
          label: letter.toUpperCase(),
          date: element.date,
        });
      });
    },
    bgSchedule(schedule) {
      if (schedule.status) {
        return "bg-late";
      } else {
        return "bg-default";
      }
    },
    openDetails(item, schedule, assistance, type) {
      let dataNull = null;
      this.titleModal = item.user_name;
      this.schedule = schedule;
      if (assistance != dataNull) {
        this.getDetailsSchedule(schedule, type);
      } else {
        this.showToast("info", "top-right", "Oops!", "XIcon", "NO ASSISTANCE");
      }
    },
    titleSchedule(schedule, assistance) {
      let dataNull = null;
      let replace = "NOT ASSIGNED";
      switch (assistance) {
        case 1:
          return schedule.checking == dataNull ? replace : schedule.checking;
        case 2:
          return schedule.begining_break == dataNull
            ? replace
            : schedule.begining_break;
        case 3:
          return schedule.finish_break == dataNull
            ? replace
            : schedule.finish_break;
        case 4:
          return schedule.checkout == dataNull ? replace : schedule.checkout;
      }
    },
    async getDetailsSchedule(schedule, type) {
      this.addPreloader();
      const params = { id: schedule.id_schedule };
      const data = await SchedulesServices.getDetailsSchedule(params);
      if (type == 1) {
        this.detailsSchedule = {
          ip: data[0].ip,
          assigned: schedule.checking,
          assistance: schedule.checking_m,
          image: data[0].images,
        };
      } else if (type == 2) {
        this.detailsSchedule = {
          ip: data[0].ip2,
          assigned: schedule.begining_break,
          assistance: schedule.m_begining_break,
          image: data[0].images2,
        };
      } else if (type == 3) {
        this.detailsSchedule = {
          ip: data[0].ip3,
          assigned: schedule.finish_break,
          assistance: schedule.m_finish_break,
          image: data[0].images3,
        };
      } else if (type == 4) {
        this.detailsSchedule = {
          ip: data[0].ip4,
          assigned: schedule.checkout,
          assistance: schedule.m_checkout,
          image: data[0].images4,
        };
      }
      this.modal = true;
      this.removePreloader();
    },
    async exportExcel() {
      this.addPreloader();
      const params = {
        items: this.filterUser,
        fields: this.fields,
        start_date: this.filter.from,
        end_date: this.filter.to,
      };
      const data = await SchedulesServices.getExportExcel(params);
      let namefile =
        "schedules_report_" +
        moment(this.filter.from).format("MM-DD-YYYY") +
        "_" +
        moment(this.filter.to).format("MM-DD-YYYY");
      this.forceFileDownload(data, namefile + ".xlsx");
      this.removePreloader();
    },
    getMoreInfo() {
      this.dataSchedule = this.filterUser;
      this.keyMoreInfo = ++this.keyMoreInfo;
    },
    resetSearch() {
      (this.filter.from = moment()
        .startOf("week")
        .add(1, "days")
        .format("YYYY-MM-DD")),
        (this.filter.to = moment()
          .endOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")),
        this.getReport();
    },
    moveSideways(number) {
      this.$refs.table_report_modul.scrollLeft = "5000px";
    },

    async getData() {
      this.addPreloader();
      const params = {
        user_id: this.selectedUser,
        start_date: moment(this.filter.from).format("YYYY-MM-DD"),
        final_date: moment(this.filter.to).format("YYYY-MM-DD"),
      };
      let f1 = moment(this.filter.from);
      let f2 = moment(this.filter.to);
      let total = f2.diff(f1, "days");
      let sum = 0;
      const res = await SchedulesServices.reportSchedulesByUser(params);
      this.filterUser = res.data.data;
      let temporary_json_data = [];
      for (let i = 0; i < this.filterUser.length; i++) {
        let day_header = [];
        for (let index = 0; index <= total; index++) {
          day_header.push({
            date: moment(f1).add(index, "days").format("YYYY-MM-DD"),
            format_day: moment(f1).add(index, "days").format("ddd MM-DD"),
            checking: null,
            begining_break: null,
            finish_break: null,
            checkout: null,
            checking_m: null,
            m_begining_break: null,
            m_finish_break: null,
            m_checkout: null,
            status: 0,
            status_jf: null,
            appv_su: null,
            appv_rrhh: null,
            appv_overtime: null,
            type: null,
            motive: null,
            sum_overtime: 0,
            horavalidada: 0,
            datetime: null,
            id_schedule: null,
            img1: null,
            img2: null,
            img3: null,
            img4: null,
            ip: null,
            ip2: null,
            ip3: null,
            ip4: null,
            total_hours: 0,
            horavalidada: 0,
            overtime: 0,
            sumH: 0,
            foults: 0,
            j_abs: 0, // variable para contar las ausencias
            j_later: 0,
          });
          this.fields = day_header;
        }
        if (this.filterUser[i].json_data == null) {
          this.filterUser[i].json_data = day_header;
        } else {
          temporary_json_data = this.filterUser[i].json_data;
          this.filterUser[i].json_data = day_header;
          for (let k = 0; k < this.filterUser[i].json_data.length; k++) {
            for (let y = 0; y < temporary_json_data.length; y++) {
              if (
                this.filterUser[i].json_data[k].date ==
                temporary_json_data[y].date
              ) {
                this.filterUser[i].json_data[k].checking =
                  temporary_json_data[y].checking;
                // ***********
                this.filterUser[i].json_data[k].begining_break =
                  temporary_json_data[y].begining_break;
                // **********
                this.filterUser[i].json_data[k].finish_break =
                  temporary_json_data[y].finish_break;
                // **********
                if (temporary_json_data[y].checkout == "00:00:00") {
                  this.filterUser[i].json_data[k].checkout = "24:00:00";
                } else {
                  this.filterUser[i].json_data[k].checkout =
                    temporary_json_data[y].checkout;
                }
                // **********
                this.filterUser[i].json_data[k].id_schedule =
                  temporary_json_data[y].id_schedule;
                this.filterUser[i].json_data[k].checking_m =
                  temporary_json_data[y].checking_m;
                // **********
                this.filterUser[i].json_data[k].m_begining_break =
                  temporary_json_data[y].m_begining_break;
                // **********
                this.filterUser[i].json_data[k].m_finish_break =
                  temporary_json_data[y].m_finish_break;
                // **********
                if (temporary_json_data[y].m_checkout == "00:00:00") {
                  this.filterUser[i].json_data[k].m_checkout = "24:00:00";
                } else {
                  this.filterUser[i].json_data[k].m_checkout =
                    temporary_json_data[y].m_checkout;
                }
                // **********
                this.filterUser[i].json_data[k].status =
                  temporary_json_data[y].status;
                // **********
                this.filterUser[i].json_data[k].img1 =
                  temporary_json_data[y].img1;
                // **********
                this.filterUser[i].json_data[k].img2 =
                  temporary_json_data[y].img2;
                // **********
                this.filterUser[i].json_data[k].img3 =
                  temporary_json_data[y].img3;
                // **********
                this.filterUser[i].json_data[k].img4 =
                  temporary_json_data[y].img4;
                // **********
                this.filterUser[i].json_data[k].ip = temporary_json_data[y].ip;
                // **********
                this.filterUser[i].json_data[k].ip2 =
                  temporary_json_data[y].ip2;
                // **********
                this.filterUser[i].json_data[k].ip3 =
                  temporary_json_data[y].ip3;
                // **********
                this.filterUser[i].json_data[k].ip4 =
                  temporary_json_data[y].ip4;
                // **********
                this.filterUser[i].json_data[k].type =
                  temporary_json_data[y].type;
                // **********
                this.filterUser[i].json_data[k].motive =
                  temporary_json_data[y].motive;
                // **************
                this.filterUser[i].json_data[k].datetime =
                  temporary_json_data[y].datetime;
                // **************
                this.filterUser[i].json_data[k].total_hours =
                  temporary_json_data[y].total_hours;
                // **************
                this.filterUser[i].json_data[k].status_jf =
                  temporary_json_data[y].status_jf;
                // **********
                this.filterUser[i].json_data[k].appv_su =
                  temporary_json_data[y].appv_su;
                // **********
                this.filterUser[i].json_data[k].appv_rrhh =
                  temporary_json_data[y].appv_rrhh;
                // **********
                this.filterUser[i].json_data[k].appv_overtime =
                  temporary_json_data[y].appv_overtime;
                // **********
                this.filterUser[i].json_data[k].hours_assig =
                  temporary_json_data[y].hours_assig;
                // **********
                this.filterUser[i].json_data[k].horavalidada =
                  temporary_json_data[y].horavalidada;
                // **********
              }
            }
          }
        }
      }
      for (let i = 0; i < this.filterUser.length; i++) {
        for (let y = 0; y < this.filterUser[i].json_data.length; y++) {
          if (this.filterUser[i].json_data[y].horavalidada == null) {
            this.filterUser[i].json_data[y].horavalidada = 0;
          }
          //   ******************** HORAS EXTRAS ********************
          if (
            this.filterUser[i].json_data[y].checking_m != null &&
            this.filterUser[i].json_data[y].m_checkout != null
          ) {
            this.filterUser[i].json_data[y].overtime =
              this.filterUser[i].json_data[y].horavalidada -
              this.filterUser[i].json_data[y].hours_assig;
          }
          if (this.filterUser[i].json_data[y].appv_overtime == 1) {
            this.filterUser[i].json_data[y].sum_overtime =
              this.filterUser[i].json_data[y].overtime;
          }
          //   ******************* FIN HORAS EXTRAS *****************
          if (
            this.filterUser[i].json_data[y].total_hours == 0 &&
            y < res.data.dayscalculate &&
            this.filterUser[i].json_data[y].checking_m == null &&
            this.filterUser[i].json_data[y].checking != null
          ) {
            this.filterUser[i].json_data[y].foults = 1;
          } else {
            this.filterUser[i].json_data[y].foults = 0;
          }
          if (
            this.filterUser[i].json_data[y].type == "late" &&
            this.filterUser[i].json_data[y].appv_rrhh == 1
          ) {
            this.filterUser[i].json_data[y].j_later = 1;
          } else {
            this.filterUser[i].json_data[y].j_later = 0;
          }
          if (
            this.filterUser[i].json_data[y].type == "abse" &&
            this.filterUser[i].json_data[y].appv_rrhh == 1
          ) {
            this.filterUser[i].json_data[y].j_abs = 1;
          } else {
            this.filterUser[i].json_data[y].j_abs = 0;
          }
          if (this.filterUser[i].json_data[y].horavalidada == null) {
            this.filterUser[i].json_data[y].horavalidada = 0;
          }
        }
      }
      this.filterUser.map(function (e, index) {
        e.json_data.push({
          lateness: e.json_data.reduce((p, c) => p + parseInt(c.status), 0),
          fouls: e.json_data.reduce((p, c) => p + parseInt(c.foults), 0),
          total_hours_filter: e.json_data.reduce(
            (p, c) => p + parseInt(c.horavalidada),
            0
          ),
          jus_later: e.json_data.reduce((p, c) => p + parseInt(c.j_later), 0),
          jus_abs: e.json_data.reduce((p, c) => p + parseInt(c.j_abs), 0),
          total_overtime: e.json_data.reduce(
            (p, c) => p + parseInt(c.sum_overtime),
            0
          ),
        });
      });

      // if (total > 7) {
      //   this.scrollTable = true;
      // } else {
      //   this.scrollTable = false;
      // }
      this.removePreloader();
    },
    async getUsersForScheduleFilter() {
      const params = { id_module: null };
      const res = await SchedulesServices.getAllUsersForSchedules(params);
      this.users = res.data;
    },
  },
  computed: {
    // filterUser: function () {
    //   return this.items.filter((item) => {
    //     return item.user_name
    //       .toLowerCase()
    //       .match(this.filter.searchUser.toLowerCase());
    //   });
    // },
    ...mapGetters({
      currentUser: "auth/currentUser",
      currentBreakPount: "app/currentBreakPoint",
    }),
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin == "dark";
    },
  },
  watch: {
    selectedUser() {
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-default {
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  min-width: 150px !important;
}
.bg-late {
  background: rgba(227, 52, 47, 0.1) !important;
  text-align: center;
  min-width: 150px !important;
}
.p-s {
  margin-top: 3px;
}
.bg-hours {
  background: rgba(143, 95, 232, 0.1) !important;
  text-align: center;
  border: 1px solid #6e6b7b;
  min-width: 150px !important;
}
.sidebar-lg-schedule {
  width: 50rem !important;
}
.title-sidebar-schedule {
  font-weight: bold;
}
.icon-adres {
  position: fixed;
  margin-top: 100px;
}
.icon-left {
  margin-left: 0rem;
}
.icon-right {
  margin-right: 0rem;
}
.btn-icon-adres {
  background-color: rgba(51, 59, 81, 0.9) !important;
}
.btn-scroll-l,
.btn-scroll-r {
  padding: 6px 10px !important;
  z-index: 90;
  position: fixed;
}
.btn-scroll-l {
  left: 120px;
}
.btn-scroll-r {
  right: 2%;
}
.image-captured {
  width: 400px !important;
}

/* Table styles */

.bg-th-today {
  background-color: #8f5fe8 !important;
  color: white;
}

.bg-hour-today {
  background: rgba(188, 159, 241, 0.15) !important;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #543e86 !important;
    color: white;
  }
}

.bg-count-hours-today {
  background: rgba(143, 95, 232, 0.5) !important;
  text-align: center;
  color: white !important;
}

table {
  border-collapse: collapse !important;

  & td,
  & &-sm td {
    padding: 0rem !important;
  }
}

.item-header {
  background: #f3f2f7;
  border: 1px solid #ebe9f1 !important;
  box-sizing: border-box;
  padding-top: 7px !important;
  width: 160px;
  height: 30px;
}

.item-user {
  position: relative;

  .container {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-top: 5px;
      color: #8e8c99;
      font-weight: 500;
    }
  }

  span {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 24.5px;
    background: rgba(143, 95, 232, 0.1);
  }
}

.item-hour {
  background: transparent;
  padding: 0px 0px 3px 0px;
  font-size: 12.5px;

  cursor: pointer;
  transition: background 0.3s ease-in-out;

  &:nth-child(4) {
    border-bottom: 1px white solid;
    margin-bottom: 0px;
  }

  &-light {
    border-bottom: 1px solid #ebe9f1 !important;

    &:hover {
      background: #f3f2f7;
    }
  }

  &-dark {
    border-bottom: 1px solid #31343e !important;

    &:hover {
      background: #252541;
    }
  }
}

.item-count-hours {
  border: none;
  color: #484848;
  font-weight: 600 !important;
  font-size: 13px;
  padding: 4px 0px 2px 0px;
  /* border: 2px solid white !important; */
}

/*.b-sidebar {
  width: 40% !important;
}*/
</style>
